import Container from '@mui/material/Container'
import Header from '../components/mainPage/Header.js';
import Body from '../components/mainPage/body/Body.js';
import BodyInformation from '../components/mainPage/body/BodyInformation.js';
import Typography from '@mui/material/Typography';
function MainPage(){
    return (
        <div>
        <Container>
            <Header></Header>
            <Container sx={{ textAlign: 'left', mt: 5 ,justifyContent:'center'}}>
                    <Typography variant="h5" component="p" sx={{ mb: 4 , fontWeight:'bold'}}>
                        Sitemiz şuanda bakımdadır.
                    </Typography>
                    <Typography variant="h5" component="p" sx={{ mb: 4 , fontWeight:'bold' }}>
                        +90 536 339 7438 telefonundan dükkan sahibine ulaşabilirsiniz.
                    </Typography>
                    <Typography variant="h5" component="p" sx={{ mb: 4 , fontWeight:'bold'}}>
                        Anlayışınız için teşekkürler...
                    </Typography>
            </Container>
            
        </Container>
        </div>

    )
}

export default MainPage